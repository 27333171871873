import MarqueeI from "react-fast-marquee";
import col01 from "../assets/col/01.png";
import col02 from "../assets/col/02.png";
import img1 from "../assets/col/18.png";
import img2 from "../assets/col/17.png";
import img3 from "../assets/col/16.png";
import img4 from "../assets/col/15.png";
import img5 from "../assets/col/14.png";

import img7 from "../assets/col/12.png";

function Marquee(props) {
	return (
		<div className='marquee'>
			<MarqueeI speed={30}>
				<div className='logos_col'>
					<img src={img1} alt='' className='' />
					<img src={img3} alt='' className='' />
					{/* <img src={img3} alt='' className='blur' /> */}
					<img src={img4} alt='' className='' />
					<img src={img5} alt='' className='' />
					<img src={img7} alt='' className='' />
					<img src={img2} alt='' />
				</div>
			</MarqueeI>
		</div>
	);
}

export default Marquee;
